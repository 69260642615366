import React from "react"

// Components
import Seo from "../components/seo"
import Header from "../components/header"
import Hero from "./screens/minutes-matter/hero"
import FormLanding from "./screens/minutes-matter/form-landing"
import BodyLanding from "../components/body-landing"

const MinutesMatter = () => {
	return (
		<main>
			<Header />
			<Hero />
			<FormLanding />
			<BodyLanding landingPage="minutes-matter" />
		</main>
	)
}

export default MinutesMatter

export const Head = () => (
	<Seo
	  title="Minutes Matter"
	  description="Get PCR STI results in 28 minutes to improve patient length of stay, relieve resource strain and patient satisfaction scores."
	  recaptcha
	/>
  )
